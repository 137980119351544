import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { jwtDecode as jwt_decode } from 'jwt-decode'

// ** utils
import { getHomeRouteForLoggedInUser } from '@utils'

// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'

// Actions
export const login = createAsyncThunk(
  'auth/login',
  async ({ data, ability, navigate }, { rejectWithValue }) => {
    try {
      const response = await useJwt.login(data)
      const { access, refresh } = response.data
      useJwt.setToken(access)
      useJwt.setRefreshToken(refresh)
      const decoded = jwt_decode(access)
      const is_staff = decoded?.is_staff
      const provider_avatar = decoded?.avatar
      if (is_staff) {
        useJwt.setUserData({ role: is_staff, decoded })
        localStorage.setItem('avatar', provider_avatar)
        const userData = useJwt.getUserData()
        toast((t) => (
          <ToastContent
            t={t}
            name={decoded?.email}
            icon={<Check size={14} />}
            color="success"
            msg={'You have successfully logged in '}
          />
        ))
        ability.update(userData.ability)
        navigate(getHomeRouteForLoggedInUser(is_staff))
      }
      if (!is_staff) {
        localStorage.clear()
        toast((t) => (
          <ToastContent
            t={t}
            name={decoded?.email}
            icon={<X size={14} />}
            color="danger"
            msg={'You do not have permission to perform this action'}
          />
        ))
      }
      return decoded
    } catch (err) {
      const errors = err?.response?.data
      if (errors && err?.response.status !== 401) {
        Object.keys(errors).map((key) => {
          toast((t) => (
            <ToastContent
              t={t}
              name={'Error'}
              icon={<X size={14} />}
              color="danger"
              msg={errors?.non_field_errors?.[0] || errors.msg}
            />
          ))
        })
      } else if (err?.response.status === 401) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Login Error'}
            icon={<X size={14} />}
            color="danger"
            msg={errors?.detail}
          />
        ))
      }
      return rejectWithValue(err?.response?.data || err.message)
    }
  }
)

//**  Create New Password    */
export const forgotPasswordAction = createAsyncThunk(
  'auth/forgotPassword',
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.forgotPassword(data)
      toast((t) => (
        <ToastContent
          t={t}
          name="Reset link sent successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name="Invalid Email"
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**  Reset  Password    */
export const ResetPasswordAction = createAsyncThunk(
  'auth/ResetPassword',
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.ResetPassword(data)
      toast((t) => (
        <ToastContent
          t={t}
          name="Password Updated Successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)
