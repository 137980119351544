import { useEffect, useState, useCallback, useRef } from 'react'
// ** Third Party Packages
import { Icon } from '@iconify/react'
import moment from 'moment'
import { Check, X, Calendar } from 'react-feather'
import {
  Badge,
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table
} from 'reactstrap'
// ** Components
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index' // Import the month select plugin
import 'flatpickr/dist/plugins/monthSelect/style.css' // Import the CSS for month select plugin
// ** Actions and Store
import useScrollBar from '@hooks/useScrollBar'
import useJwt from '@src/auth/jwt/useJwt'
import SpinnerComponent from '../../@core/components/spinner/Fallback-spinner'
import ComponentSpinner from '../../@core/components/spinner/Loading-spinner'
import ExportSpinner from '../../@core/components/spinner/ExportSpinner'
import { set } from 'lodash'

function ExportModal({ title, open, handleCloseModal }) {
  useScrollBar({ open })
  const currentDate = moment()
  const today = moment(currentDate).startOf('month').toDate()
  const nextMonth = moment().add(1, 'month').endOf('month').toDate()
  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(nextMonth)
  const [selectedOption, setSelectedOption] = useState('')
  const [specificMonth, setSpecificMonth] = useState(today)
  const [loadingScreen, setLoadingScreen] = useState(false)
  const [error, setError] = useState('')
  const controllerRef = useRef()
  const getDisabledMonths = () => {
    const now = moment() // Current date
    const startOfNextMonth = now.add(1, 'month').startOf('month').toDate() // Start of the next month

    return [
      {
        from: startOfNextMonth, // Disable future months from the start of the next month
        to: null // Disable all dates after this point
      }
    ]
  }
  const handleChange = (event) => {
    if (error) {
      setError('')
    }
    setSelectedOption(event.target.value)
  }

  // ** States
  const CloseBtn = (
    <X
      className="pointer fw-600"
      size={20}
      onClick={() => {
        if (controllerRef.current) {
          controllerRef.current.abort()
        }
        handleCloseModal()
      }}
    />
  )
  const exportInvoiceFileHandler = async () => {
    try {
      if (error) {
        return
      }
      const controller = new AbortController()
      controllerRef.current = controller
      const signal = controllerRef.current.signal
      setLoadingScreen(true)
      let data
      if (selectedOption === 'custom') {
        data = {
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD')
        }
      } else if (selectedOption === 'single-month') {
        data = {
          start_date: moment(specificMonth).format('YYYY-MM-DD')
        }
      } else if (selectedOption === 'current-month') {
        data = {
          start_date: moment().startOf('month').format('YYYY-MM-DD')
        }
      } else {
        data = {}
      }
      const response = await useJwt.getExportInvoicesFile(data, signal)
      setLoadingScreen(false)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'invoices.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (err) {}
  }
  const handleDateChange = (dates, field) => {
    const currentMonth = moment().startOf('month')
    const selectedMonth = moment(dates[0]).startOf('month')
    if (field === 'startDate') {
      if (endDate && dates[0] > endDate) {
        setError('End date cannot be earlier than the start date.')
        setStartDate(today)
      } else if (selectedMonth.isAfter(currentMonth)) {
        setError('Start date cannot be in the future.')
        setStartDate(today)
      } else {
        setError('')
        setStartDate(dates[0])
      }
    } else if (field === 'endDate') {
      if (startDate && dates[0] < startDate) {
        setError('End date cannot be earlier than the start date.')
        setEndDate(nextMonth)
      } else if (selectedMonth.isAfter(nextMonth)) {
        setError('End date cannot be in the future.')
        setEndDate(nextMonth)
      } else {
        setError('')
        setEndDate(dates[0])
      }
    } else if (field === 'specificMonth') {
      if (selectedMonth.isAfter(moment().add(1, 'months').startOf('month'))) {
        setError('Selected date cannot be in the future.')
        setSpecificMonth(today)
      } else {
        setError('')
        setSpecificMonth(dates[0])
      }
    }
  }
  return (
    <Modal
      scrollable={true}
      isOpen={open}
      className="modal-dialog-centered calendarModal "
    >
      <ModalHeader
        className="mb-1 ethera-modal-top-background"
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title ethera-dark fw-600">Export Invoices</h5>
      </ModalHeader>

      <ModalBody className="mt-1 flex-grow-1">
        {loadingScreen ? (
          <div className="d-flex justify-content-center flex-column p-3">
            <ExportSpinner />
            <p
              className="text-center mt-4"
              style={{ fontSize: '16px', lineHeight: '28px' }}
            >
              Please wait while your file is being exported. Ensure this page
              remains open for a successful process.
            </p>
          </div>
        ) : (
          <div
            style={{
              padding: '1rem 3rem',
              height: '210px',
              marginBottom: '10px'
            }}
          >
            <Input
              type="radio"
              id="Export"
              value="export-all"
              checked={selectedOption === 'export-all'}
              onChange={handleChange}
            />
            <Label
              for="Export"
              className="ml-1 mb-1"
              style={{ fontSize: '15px', cursor: 'pointer' }}
            >
              Export All
            </Label>
            <br />
            <Input
              type="radio"
              id="CurrentMonth"
              value="current-month"
              checked={selectedOption === 'current-month'}
              onChange={handleChange}
            />
            <Label
              for="CurrentMonth"
              className="ml-1 mb-1"
              style={{ fontSize: '15px', cursor: 'pointer' }}
            >
              Current Month
            </Label>
            <br />
            <Input
              type="radio"
              id="SingleMonth"
              value="single-month"
              checked={selectedOption === 'single-month'}
              onChange={handleChange}
            />
            <Label
              for="SingleMonth"
              className="ml-1 mb-1"
              style={{ fontSize: '15px', cursor: 'pointer' }}
            >
              Select Single Month
            </Label>
            {selectedOption === 'single-month' && (
              <div
                id="single-month"
                className="d-flex justify-content-between"
                style={{ columnGap: '15px', width: '130px' }}
              >
                <div
                  className="AppointmentSelectors_left-dates bookings-header--left__calendar d-f-center skin-change date-Picker-new"
                  style={{
                    paddingRight: '0',
                    minWidth: '130px',
                    marginBottom: '0'
                  }}
                >
                  <Flatpickr
                    id="startDatePicker"
                    name="datePicker"
                    cursor="pointer"
                    className="form-control datePicker-non-visible"
                    onChange={(dates) =>
                      handleDateChange(dates, 'specificMonth')
                    }
                    options={{
                      disable: getDisabledMonths(),
                      enableTime: false,
                      dateFormat: 'F j, Y',
                      plugins: [
                        monthSelectPlugin({
                          shorthand: true, // Display months as shorthand (Jan, Feb, etc.)
                          dateFormat: 'Y-m', // Format to show year and month
                          altFormat: 'F Y' // Alternative format for display
                        })
                      ]
                    }}
                  />
                  <Label
                    htmlFor="startDatePicker"
                    className="mb-0 pointer d-flex align-items-center"
                    style={{ width: '100%', marginBottom: '0' }}
                  >
                    <Calendar size={20} color="#fff" />
                    <span className="whiteSpace fs-s-med">
                      {moment(specificMonth).format('MMM YYYY')}
                    </span>
                  </Label>
                </div>
              </div>
            )}
            <br />
            <Input
              type="radio"
              id="Month Range"
              value="custom"
              checked={selectedOption === 'custom'}
              onChange={handleChange}
            />
            <Label
              for="Month Range"
              className="ml-1 mb-1"
              style={{ fontSize: '15px', cursor: 'pointer' }}
            >
              Select Month Range
            </Label>
            {selectedOption === 'custom' && (
              <div
                id="custom-months"
                className="d-flex justify-content-between"
                style={{ columnGap: '15px' }}
              >
                <div
                  className="AppointmentSelectors_left-dates bookings-header--left__calendar d-f-center skin-change date-Picker-new"
                  style={{ paddingRight: '0', marginBottom: '0' }}
                >
                  <Flatpickr
                    id="startDatePicker"
                    name="datePicker"
                    className="form-control datePicker-non-visible"
                    onChange={(dates) => handleDateChange(dates, 'startDate')}
                    options={{
                      disable: getDisabledMonths(),
                      enableTime: false,
                      dateFormat: 'F j, Y',
                      plugins: [
                        monthSelectPlugin({
                          shorthand: true, // Display months as shorthand (Jan, Feb, etc.)
                          dateFormat: 'Y-m', // Format to show year and month
                          altFormat: 'F Y' // Alternative format for display
                        })
                      ]
                    }}
                  />
                  <Label
                    htmlFor="startDatePicker"
                    className="mb-0 pointer d-flex align-items-center"
                    style={{ width: '100%' }}
                  >
                    <Calendar size={20} color="#fff" />
                    <span className="whiteSpace fs-s-med">
                      <strong className="fs-s-med">From: </strong>
                      {moment(startDate).format('MMM YYYY')}
                    </span>
                  </Label>
                </div>
                <div
                  className="AppointmentSelectors_left-dates bookings-header--left__calendar d-f-center skin-change date-Picker-new "
                  style={{ paddingRight: '0', marginBottom: '0' }}
                >
                  <Flatpickr
                    id="endDatePicker"
                    name="datePicker"
                    cursor="pointer"
                    className="form-control datePicker-non-visible"
                    onChange={(dates) => handleDateChange(dates, 'endDate')}
                    options={{
                      enableTime: false,
                      dateFormat: 'F j, Y',
                      plugins: [
                        monthSelectPlugin({
                          shorthand: true, // Display months as shorthand (Jan, Feb, etc.)
                          dateFormat: 'Y-m', // Format to show year and month
                          altFormat: 'F Y' // Alternative format for display
                        })
                      ]
                    }}
                  />
                  <Label
                    htmlFor="endDatePicker"
                    className="mb-0 pointer d-flex align-items-center"
                    style={{ width: '100%' }}
                  >
                    <Calendar size={20} color="#fff" />
                    <span className="whiteSpace fs-s-med">
                      <strong className="fs-s-med">To: </strong>
                      {moment(endDate).format('MMM YYYY')}
                    </span>
                  </Label>
                </div>
              </div>
            )}
            {error && (
              <div
                style={{
                  color: '#ea5455',
                  fontSize: '14px',
                  marginTop: '10px',
                  lineHeight: '5px',
                  marginBottom: '5px'
                }}
              >
                {error}
              </div>
            )}
          </div>
        )}
        {!loadingScreen && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'auto',
              gap: '20px',
              paddingTop: '0.5rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              paddingBottom: '2rem'
            }}
          >
            <Button
              className="button-success w-100"
              onClick={exportInvoiceFileHandler}
              disabled={!selectedOption || error}
            >
              <span className="px-1">Export</span>
            </Button>

            <Button className="button-cancel w-100" onClick={handleCloseModal}>
              <span className="px-1">Cancel</span>
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
export default ExportModal
