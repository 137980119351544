/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'

// ** Third Party Packages
import Spinner from '@spinner'
import moment from 'moment'
import debounce from 'lodash/debounce'
import DataTable, { createTheme } from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { Card, Table } from 'reactstrap'

// ** Components
import BillingHeader from '@ScreenComponent/billing/header'
import CustomPagination from '@pagination/ReactPaginate'
import { columns } from './table.data'

// ** Store & Actions
import {
  getAllBillingAction,
  handleLimitChange,
  handlePageChange
} from '@store/billing/billingAction'
import { resetBillings } from '@store/billing/billingSlice'
import { useDispatch, useSelector } from 'react-redux'
import NotFound from '../../components/not-found'

createTheme(
  'solarized',
  {
    text: {
      primary: '#',
      secondary: '#2aa198'
    },
    background: {
      default: 'transparent'
    },
    context: {
      background: '#e3f2fd',
      text: '#000'
    },
    divider: {
      default: 'rgba(216, 214, 222, 0.1)'
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)'
    }
  },
  'dark'
)

function Billing() {
  const dispatch = useDispatch()
  const { loading, getAllBillings } = useSelector((state) => state.billing)

  const rows = getAllBillings?.billingsList
  let totalAmount
  if (rows && rows.length > 0) {
    totalAmount = rows?.reduce((prev, next) => {
      return prev + next.total_amount
    }, 0)
  }

  // ** Getting Billings
  useEffect(() => {
    dispatch(
      getAllBillingAction({
        offset: 0,
        limit: 1000000,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
      })
    )
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetBillings())
    }
  }, [])

  return (
    <Card>
      <div className="p-2 pt-3 bg-yellow d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <span className="heading-1 ">Invoices</span>

          <span className="heading-3 mx-1 ">
            {moment().format('MMMM YYYY')}
          </span>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Table bordered responsive id="invoice-list">
          <thead className="bgThead">
            <tr>
              {columns.map(({ header }, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, i) => {
              return (
                <tr key={i} className="tableRow">
                  <td>{row?.invoice_number || '--'}</td>
                  <td>
                    {`${row?.provider?.first_name || '--'} ${
                      row?.provider?.last_name || '--'
                    }`}
                  </td>
                  <td>{row?.total_amount}</td>
                </tr>
              )
            })}

            <tr className="borderLess-cell bgGray">
              <td>Total</td>
              <td></td>

              <td className="whiteSpace">$ {totalAmount}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Card>
  )
}

export default Billing
